import { propertiesToCamel } from '../utils'

const parseSubmitter = data => {
  return propertiesToCamel(data.attributes)
}

const parseLead = data => {
  return {
    id: data.id,
    ...propertiesToCamel(data.attributes),
    createdAt: data.attributes.created_at * 1000,
    submitter: parseSubmitter(data.attributes.submitter.data),
  }
}

export const parseResponse = response => {
  return {
    data: response.data.map(parseLead),
    meta: {
      totalCount: response.meta.page.total_count,
      ...response.links,
    },
  }
}
