import { propertiesToCamel } from '../utils'

const parseAction = data => {
  return {
    id: data.id,
    ...propertiesToCamel(data.attributes),
  }
}
// eslint-disable-next-line import/prefer-default-export
export const parseResponse = response => {
  return {
    data: response.data.map(parseAction),
    meta: {
      totalCount: response.meta.page.total_count,

      ...response.links,
    },
  }
}
