import qs from 'qs'

const HOST = process.env.REACT_APP_BACKEND_URL

export const STATIC_HEADERS = {
  'Content-Type': 'application/vnd.api+json',
  'X-Integration-Source': 'livechat'
}

export const getQuestions = (filters, groupId, accessToken) => {
  const serializedFilters = qs.stringify(filters, { arrayFormat: 'comma' });

  return fetch(`${HOST}/v1/knowledge_base_entries?${serializedFilters}`, {
    method: 'GET',
    headers: {
      ...STATIC_HEADERS,
      'X-Livechat-Group-Id': groupId,
      'X-Livechat-Access-Token': accessToken
    },
  })
    .then(resp => {
      if (resp.status !== 200) {
        throw new Error(resp.status)
      }
      return resp
    })
    .then(res => res.json())
};

export const createQuestion = data => {
  return fetch(`${HOST}/v1/knowledge_base_entries?`, {
    method: 'POST',
    headers: {
      ...STATIC_HEADERS,
      'X-Livechat-Group-Id': data.groupId,
      'X-Livechat-Access-Token': data.accessToken
    },
    body: JSON.stringify({
      data: {
        type: 'knowledge_base_entry',
        attributes: {
          question: data.question,
          suggestion: data.answer
        },
      },
    }),
  })
    .then(resp => {
      if (![200, 201].includes(resp.status)) {
        throw new Error(resp.status)
      }
      return resp
    })
    .then(res => res.json())
};

export const submitClientInformation = data => {
  return fetch(`${HOST}/v1/livechat_leads${data.id ? `/${data.id}` : ''}`, {
    method: data.id ? 'PUT' : 'POST',
    headers: {
      ...STATIC_HEADERS,
      'X-Livechat-Group-Id': data.groupId,
      'X-Livechat-Access-Token': data.accessToken
    },
    body: JSON.stringify({
      data: {
        type: 'livechat_lead',
        attributes: {
          comments: data.actionToTake,
          email: data.email,
          first_name: data.firstName,
          last_name: data.lastName,
          livechat_chat_id: data.livechatChatId,
          livechat_thread_id: data.livechatThreadId,
          phone: data.phone,
          workspace_area_id: data.areaId,
        }
      },
    }),
  }).then(res => res.json())
};

export const getGroup = (accessToken, groupId) => {
  return fetch(`${HOST}/v1/livechat_groups/${groupId}`, {
    method: 'GET',
    headers: {
      ...STATIC_HEADERS,
      'X-Livechat-Access-Token': accessToken,
      'X-Livechat-Group-Id': groupId
    },
  })
    .then(resp => {
      if (resp.status !== 200) {
        throw new Error(resp.status)
      }
      return resp
    })
    .then(res => res.json())
    .catch(e => {
      console.error('Error fetching the Group', e);
      return {};
    })
};
