import { useState, useEffect } from 'react'

import { parseResponse, prepareFilters } from './utils'
import { genericGet } from './api'
import useLiveChat from '../application/livechat'

const resourceBasePath = '/v1/workspaces?'

const DEFAULT_FILTER = 'name';

const useWidgetWorkspaces = () => {
  const [widgetWorkspaces, setWidgetWorkspaces] = useState([])
  const [{ groupId, accessToken }] = useLiveChat();
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState();

  const getWidgetWorkspacesFirstPage = () => {
    setLoading(true)
    setWidgetWorkspaces([])
    return genericGet(
      resourceBasePath, {
        filter: prepareFilters({}),
        sort: DEFAULT_FILTER
      },
      groupId,
      accessToken
    )
      .then(parseResponse)
      .then(response => {
        setWidgetWorkspaces(response.data)
        setMeta(response.meta)
      })
      .catch(e => {
        console.error(e)
      })
      .then(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (accessToken && groupId) {
      getWidgetWorkspacesFirstPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, groupId])

  return [
    {
      loading,
      widgetWorkspaces,
      meta,
    }
  ]
}

export default useWidgetWorkspaces
