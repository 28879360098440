import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import useApplication from '../../store/application/hook';
import { useIntl } from 'react-intl';

const Wrapper = styled.div`
background-color: #fff;
padding: 13px 14px 18px 14px;
`;

const Search = () => {
  const [, { setFilters }]= useApplication();
  const intl = useIntl()
  return (
    <Wrapper>
      <Input
        placeholder={intl.formatMessage({ id: 'searchQuestion' })}
        style={{ border: '1px solid #dfe6e0', borderRadius: '3px', height: '42px', fontSize: '13px', color: '#8a998e' }}
        onChange={(e) => {
          if (e.target.value) {
            setFilters({
              keyword: e.target.value
            });
          } else {
            setFilters({});
          }
        }}
      />
    </Wrapper>
  );
};

export default Search;

