import qs from 'qs'
import { STATIC_HEADERS } from './application/api'

const HOST = process.env.REACT_APP_BACKEND_URL

const toCamel = s => {
  return s.replace(/([-_][a-z0-9])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  })
}
export const propertiesToCamel = obj => {
  const resp = {}

  Object.keys(obj).forEach(key => {
    resp[toCamel(key)] = obj[key]
  })

  return resp
}

export const genericGet = (path, filter = {}, groupId, accessToken) => {
  const serializedFilters = qs.stringify(filter, { arrayFormat: 'brackets' })
  return fetch(`${HOST}${path}${serializedFilters}`, {
    method: 'GET',
    headers: {
      ...STATIC_HEADERS,
      'X-Livechat-Group-Id': groupId,
      'X-Livechat-Access-Token': accessToken
    },
  }).then(res => res.json())
}