import qs from 'qs'

const HOST = process.env.REACT_APP_BACKEND_URL

export const genericGet = (path, filter = {}, groupId, accessToken) => {
  const serializedFilters = qs.stringify(filter, { arrayFormat: 'comma' })
  return fetch(`${HOST}${path}${serializedFilters}`, {
    method: 'GET',
    headers: {
      'X-Integration-Source': 'livechat',
      'X-Livechat-Group-Id': groupId,
      'X-Livechat-Access-Token': accessToken
    },
  }).then(res => res.json())
}
