export const parseGroup = group => {
  return {
    id: +group.id,
    name: group.attributes.name,
    relatedQuestions: group.attributes.entries_count,
  }
}

export const parseGroups = groups => {
  return groups.map(parseGroup)
}

const parseQuestion = question => {
  return {
    id: question.id,
    answer: question.attributes.answer,
    question: question.attributes.question,
    keywords: question.attributes.tags,
    groupId: +(((question.attributes.group || {}).data || {})).id,
    groupName: ((((question.attributes.group || {}).data || {}).attributes || {})).name,
    status: question.attributes.status,
    suggestion: question.attributes.suggestion,
    updatedAt: question.attributes.updated_at
      ? new Date(question.attributes.updated_at * 1000).toISOString()
      : null,
  }
}

export const parseQuestions = questions => {
  return questions.map(parseQuestion)
}
