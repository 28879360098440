import React from 'react';
import styled from 'styled-components';
import useApplication from '../../store/application/hook';

const Wrapper = styled.div`
  background-color: #fff;
  border-top: solid 1px #dfe6e0;
  padding-top: 13px;
  font-size: 16px;
  color: #2e332f;
  font-weight: 600;
`;

const Header = () => {
  const [{ groupName }]= useApplication();
  return (
    <Wrapper>
      {groupName}
    </Wrapper>
    );
};

export default Header;

