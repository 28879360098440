import React, { useState } from 'react';
import styled from 'styled-components';
import convert from 'htmr'
import Pill from '../Pill/Pill';
import useApplication from '../../store/application/hook';
import { Button, Popover, Input, notification, Empty } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import useLiveChat from '../../store/application/livechat';
import { useIntl } from 'react-intl';
import { Spin } from 'antd';

const StyledSpin = styled(Spin)`
  position: absolute;
  translate: transform3d(-50%, 0, 0);
  left: 50%;
  top: 120px;
`;

const HeaderWrapper = styled.div`
  border-top: solid 1px #dfe6e0;
  padding: 10px 14px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: #8a998e;
  button {
    color: #37b34a;
    line-height: 1;
    border-radius: 0;
    height: auto;
  }
`;

const AddQuestion = styled.div`
  float: right;
`;

const QuestionWrapper = styled.div`
  border-radius: 5px;
  border: solid 1px #dfe6e0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 14px 18px;
  margin-bottom: 10px;
  text-align: left;
  h4 {
    font-size: 13px;
    font-weight: 600;
    color: #2e332f;
    margin: 0 0 5px 0;
  }
  &:hover .ant-btn {
    display: block;
  }
  &:hover {
    background-color: #ffffff;
    box-shadow: 0 0 11px 0 rgba(138, 153, 142, 0.3);
    border: solid 1px #f5a623;
    div {
      color: #2e332f;
    }
    .pill {
      color: #37b34a;
    }
  }
`;

const Answer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-align: left;
  line-height: 1.46;
  font-size: 13px;
  color: #8a998e;
  display: inline;
`;

const Footer = styled.div`
  text-align: left;
  display: block;
  margin-top: 4px;

  div {
    display: inline;
  }
`;
const AddAnswerButton = styled(Button)`
  float: right;
  display: none;
  background-color: #f5a623;
  border: none;
  line-height: 1;
  padding: 0;
`;

function replaceAngleBrackets(text) {
  // replace < (u003c) for (
  text = text.replace(/\u003c/g, '(');
  // replace > (u003e) for )
  text = text.replace(/\u003e/g, ')');
  return text;
}


const Question = ({ question, answer, groupName, keywords }) => {
  const [,{ putMessage }] = useLiveChat();

  const safeAnswerText = replaceAngleBrackets(answer)

  return (
    <QuestionWrapper>
      <AddAnswerButton type="primary" shape="circle" icon="plus" onClick={() => {
        putMessage(answer)
      }} />
      <h4>
        {question}
      </h4>
      <Answer>
        {convert(safeAnswerText || '')}
      </Answer>
      <Footer>
        {groupName && (
          <Pill>
            {groupName}
          </Pill>
        )}
        <div>
          <i>{(keywords || []).join(', ')}</i>
        </div>
      </Footer>
    </QuestionWrapper>
  );
};

const SuggestQuestionFormWrapper = styled.div`
  padding: 0 18px 20px 18px;
  input {
    margin-bottom: 11px;
  }
  textarea {
    margin-bottom: 16px;
  }
  button {
    width: 96px;
    height: 33px;
    border-radius: 3px;
    background-color: #f5a623;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    border: none;
  }
`;
const SuggestQuestionForm = ({ onSubmit }) => {
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const intl = useIntl()
  return (
    <SuggestQuestionFormWrapper>
      <Input placeholder={intl.formatMessage({id: "requiredQuestion"})} value={question} onChange={e => setQuestion(e.target.value)} />
      <TextArea
        rows={2}
        placeholder={intl.formatMessage({id: "suggestedAnswer"})}
        value={answer}
        onChange={e => setAnswer(e.target.value)}
        style={{resize: 'none', height: '82px'}}
      />
      <div style={{textAlign: 'center'}}>
      <Button
        onClick={() => {
          onSubmit({
            question,
            answer
          });

          // clean-up fields
          setQuestion('');
          setAnswer('');
        }}
        disabled={!question}
      >{intl.formatMessage({id: "send"})}</Button>
      </div>
    </SuggestQuestionFormWrapper>
  );
};

const HeaderPopup = styled.div`
  padding: 11px 18px;
  span {
    font-size: 13px;
    font-weight: 600;
    color: #2e332f;
  }
  button {
    border: none;
    box-shadow: none;
  }
`;

const StyledButton = styled(Button)`
  padding: 0;
  user-select: none;
`;

const SuggestQuestionTitle = ({ onClose }) => {
  const intl = useIntl();

  return (
    <HeaderPopup>
      <span>{intl.formatMessage({id: "suggestQuestion"})}</span>
      <Button size="small" icon='close' style={{ float: 'right' }} onClick={onClose} />
    </HeaderPopup>
  )
};

const Questions = () => {
  const [{ filters, questions, loading }, { createQuestion }] = useApplication();
  const [showSuggestQuestionModal, setShowSuggestQuestionModal] = useState();
  const intl = useIntl();

  const onSuggestQuestion = ({ question, answer }) => {
    createQuestion({ question, answer });
    setShowSuggestQuestionModal(false);

    notification.success({
      message: intl.formatMessage({id : 'suggestionSent'}),
      description:
      intl.formatMessage({id : 'revisionUntilConfirmation'}),
    });
  };

  const Wrapper = styled.div`
    padding: 0 14px;
  `;

  const StyledWrapper = styled(Wrapper)`
    > div {
      margin: 0 0 21px 0;
    }
  `;

  return (
    <>
      {loading && (
        <StyledSpin />
      )}
      {
        !filters.keyword ? (
          <>
            <HeaderWrapper />
            <StyledWrapper>
              <div>
                {intl.formatMessage({id: 'useSearchInput'})}
              </div>
            </StyledWrapper>
          </>
          ) : (
        <>
          <HeaderWrapper>
            <span>
              {intl.formatMessage({id: 'resultsAmount'}, { amount: questions.length })}
            </span>
            <AddQuestion>
              {intl.formatMessage({id: 'noAnswerSuggestion'})}{' '}
              <Popover
                placement="bottom"
                content={<SuggestQuestionForm onSubmit={onSuggestQuestion} />}
                title={<SuggestQuestionTitle onClose={() => setShowSuggestQuestionModal(false)} />}
                trigger="click"
                visible={showSuggestQuestionModal}
              >
                <StyledButton
                  type="link"
                  onClick={() => setShowSuggestQuestionModal(!showSuggestQuestionModal) }
                >
                  {intl.formatMessage({id: 'ask'})}
                </StyledButton>
              </Popover>
            </AddQuestion>
          </HeaderWrapper>
          <Wrapper>

            {
              questions.length ? (
                questions.map((question, index) => (
                  <div key={index}>
                    {/* {index > 0 && (<hr />) } */}
                    <Question {...question} />
                  </div>
                ))
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={intl.formatMessage({ id: 'noResults' })} />
              )
            }
          </Wrapper>
        </>
      )}
    </>
  );
};

export default Questions;

