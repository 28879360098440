import React from 'react'
import { Select as AntdSelect } from 'antd'
import styled from 'styled-components'

const StyledAntdSelect = styled(AntdSelect)`
  display: block;
  text-align: left;
  min-height: 42px;
  font-size: 16px;
  color: #575a57;

  > div {
    min-height: 42px;

    > div {
      line-height: 42px;
    }
  }

  & :global(.ant-select-selection__clear) {
    line-height: inherit !important;
  }

  i {
    color: green;
    margin-left: -7px;

    &[class*='anticon-down'] {
      font-size: 16px !important;
    }

    &[class*='anticon-close-circle'] svg {
      width: 16px;
      height: 16px;
    }
  }
`

const Select = React.forwardRef(({ className, ...props }, ref) => (
  <StyledAntdSelect ref={ref} className={className} {...props} />
))

export default Select
