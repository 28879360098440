import React, { createContext, useContext, useEffect, useRef } from 'react';
import * as LiveChat from "@livechat/agent-app-sdk";
import { accountsSdk } from "@livechat/accounts-sdk";
import { useState } from "react";

import { getGroup as getGroupApi } from './api';

export const StoreContext = createContext({});

const actions = {};

export const LiveChatContext = ({ children }) => {
  const [groupId, setGroupId] = useState();
  const [livechatThreadId, setLivechatThreadId] = useState();
  const [livechatChatId, setLivechatChatId] = useState();
  const [agentEmail, setAgentEmail] = useState();
  const [groupName, setGroupName] = useState();
  const [accessToken, setAccessToken] = useState();
  const [loading, setLoading] = useState();
  const firstInitialization = useRef(false);
  const putMessage = useRef(false);

  actions.customerProfile = (data) => {
    if (data && data.chat) {
      setGroupId(data.chat.groupID); // -> livechat group id
      setLivechatThreadId(data.chat.thread_id);
      setLivechatChatId(data.chat.chat_id);
      if (accessToken) {
        getGroup(data.chat.groupID);
      }
    }
  };

  actions.onIdentityFetched = (error, identityData) => {
    if (identityData && identityData.access_token) {
      if (!firstInitialization.current) {
        firstInitialization.current = true;
        setAccessToken(identityData.access_token);
        setAgentEmail(identityData.entity_id);
        LiveChat.createDetailsWidget().then((w) => { // TODO: this method is called twice, one is enough
          w.on('customer_profile', (data) => actions.customerProfile(data));
          putMessage.current = (text) => {
            return w.putMessage(text)
          }
        }).catch((e) => {
          console.error(e);
        });
        setTimeout(refreshToken, identityData.expires_in * 1000);
      }
    } else {
      console.error('Error during accountsSDK initialization', error);
    }
  };

  const getGroup = (groupId) => {
    setLoading(true);
    setGroupName('...');
    getGroupApi(accessToken, groupId).then(({ data }) => {
      setGroupName(data.attributes.group_name);
      setLoading(false);
    });
  };

  const refreshToken = () => {
    accountsSdk.init({
      client_id: process.env.REACT_APP_LIVECHAT_CLIENT_ID,
      onIdentityFetched: (error, identityData) => {
        if (identityData && identityData.access_token) {
          setAccessToken(identityData.access_token);
        } else {
          console.error('Error during accountsSDK initialization', error);
        }
        setTimeout(refreshToken, identityData.expires_in * 1000);
      }
    });
  };

  useEffect(() => {
    accountsSdk.init({
      client_id: process.env.REACT_APP_LIVECHAT_CLIENT_ID,
      onIdentityFetched: (error, identityData) => actions.onIdentityFetched(error, identityData)
    });

    // If you want to run the widget locally in Livechat. Comment the following block
    if (process.env.NODE_ENV === 'development') {
      const mockedData = {
        accessToken: 'llaO1WCtHaQghMZp0VSkrbHjZ042858uxnWzcLYi87oPRQymGhZOoLW8lmIDehup',
        groupId: '198', // livechat group id
        thread_id: 'S5NVDVZMHR',
        chat_id: 'S5MVVQK33H'
      }
      // Harcode the access token for development environment
      setAccessToken(mockedData.accessToken)
      setTimeout(() => {
        actions.customerProfile({
          chat: {
            groupID: mockedData.groupId,
            id: mockedData.thread_id, // the id is the thread id
            thread_id: mockedData.thread_id,
            chat_id: mockedData.chat_id
          }
        })
      }, 1000);
    }
  }, []);

  if (process.env.NODE_ENV === 'development') {
    const mockedData2 = {
      accessToken: 'llaO1WCtHaQghMZp0VSkrbHjZ042858uxnWzcLYi87oPRQymGhZOoLW8lmIDehup',
        groupId: '198', // livechat group id
        thread_id: 'S5NVDVZMHR', // thread id
        chat_id: 'S5MVVQK33H'
    };
    window.simulateChatNavigation = () => actions.customerProfile({
      chat: {
        groupID: mockedData2.groupId,
            id: mockedData2.thread_id, // the id is the thread id
            thread_id: mockedData2.thread_id,
            chat_id: mockedData2.chat_id
      }
    });
  }

  return (
    <StoreContext.Provider
      value={[{
        groupId,
        livechatThreadId,
        livechatChatId,
        agentEmail,
        groupName,
        accessToken,
        loading
      }, {
        putMessage: putMessage.current
      }]}
    >
      {children}
    </StoreContext.Provider>
  );
};


const useLiveChat = () => useContext(StoreContext);

export default useLiveChat;
