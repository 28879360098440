import React from 'react';
import "antd/dist/antd.css";
import Questions from './components/Questions';
import Search from './components/Search';
import { ApplicationContext } from './store/application/hook';
import { LiveChatContext } from './store/application/livechat';
import Header from './components/Header';

import './App.css';
import ClientInformation from './components/ClientInformation';
import { IntlProvider } from 'react-intl';

import messages_es from "./translations/es.json";

const messages = {
  'es': messages_es
};

function App() {
  return (
    <IntlProvider locale="es" messages={messages.es}>
      <LiveChatContext>
        <ApplicationContext>
          <div className="App">
            <Header />
            <Search />
            <Questions />
            <ClientInformation />
          </div>
        </ApplicationContext>
      </LiveChatContext>
    </IntlProvider>
  );
}

export default App;
