
import React, { useContext, createContext, useState, useEffect } from 'react'
import { getQuestions as getQuestionsApi,
  createQuestion as createQuestionApi,
  submitClientInformation as submitClientInformationApi,
} from './api';
import { parseQuestions } from './utils';
import useLiveChat from './livechat';
import { notification } from 'antd';
import { useIntl } from 'react-intl';

export const StoreContext = createContext({});

export const ApplicationContext = ({ children }) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [{ groupId, livechatChatId, livechatThreadId, accessToken, groupName }] = useLiveChat();
  const [filters, setFilters] = useState({});
  
  const intl = useIntl();

  useEffect(() => {
    if (groupId && accessToken && filters.keyword) {
      setLoading(true);
      getQuestionsApi({ filter: filters}, groupId, accessToken)
        .then(resp => parseQuestions(resp.data))
        .then(setQuestions)
        .catch(() => {
          notification.error({
            message: intl.formatMessage({id : 'oops'}),
            description: intl.formatMessage({id : 'oopsDescription'}),
          });
        }).then(() => {
          setLoading(false);
        })
    } else {
      setQuestions([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, groupId, accessToken]);

  const createQuestion = data => {
    // Prevent to send a question without groupId
    if (!groupId) {
      notification.error({
        message: intl.formatMessage({id : 'oops'}),
        description: intl.formatMessage({id : 'oopsDescription'}),
      });
      return Promise.resolve();
    }
    return createQuestionApi({
      ...data,
      groupId,
      accessToken
    }).catch(() => {
      notification.error({
        message: intl.formatMessage({id : 'oops'}),
        description: intl.formatMessage({id : 'oopsDescription'}),
      });
    });
  };

  const submitClientInformation = (data) => {
    // Prevent to send a question without groupId
    if (!groupId) {
      notification.error({
        message: intl.formatMessage({id : 'oops'}),
        description: intl.formatMessage({id : 'oopsDescription'}),
      });
      return Promise.reject();
    }
    return submitClientInformationApi({
      ...data,
      groupId,
      accessToken,
    }).catch(() => {
      notification.error({
        message: intl.formatMessage({id : 'oops'}),
        description: intl.formatMessage({id : 'oopsDescription'}),
      });
    });
  };

  return (
    <StoreContext.Provider
      value={[
        {
          filters,
          loading,
          groupName,
          questions,
          livechatThreadId,
          livechatChatId
        },
        {
          setFilters,
          createQuestion,
          submitClientInformation,
        },
      ]}
    >
      {!(groupId || loading)  && 
        <div style={{position: 'absolute',top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(128, 128, 128, 0.4)', zIndex: 10}} />
      }
      {children}
    </StoreContext.Provider>
  )
};

const useApplication = () => useContext(StoreContext);

export default useApplication;
